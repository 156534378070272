import { useTitle } from 'react-use';

import { OTPCheckoutHeadcount } from '../components/OneTimePurchase/OTPCheckoutHeadcount';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export const clientLoader = async () => {
  return {};
};

export function Component() {
  const { pack, product } = useCheckoutLoaderData();
  useTitle(makeTitle(`Select Headcount | Purchase ${pack.name}`));

  return <OTPCheckoutHeadcount pack={pack} product={product} />;
}
